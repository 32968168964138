import React, { FC, ReactElement, useEffect, useState } from 'react';
import { comparisonInfo, formatRichText } from '@/tools';
import { Space, Image, Tag, Descriptions, Typography, Modal, Row, Col } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import SkuTable from '../SkuTable';
import ScopeTable from '../scopeTable';
import './index.less';

const { Title, Text } = Typography;

const TaxRateOption = new Map([
	[0, '不开票'],
	[1, '6%增值税普通发票'],
	[2, '6%增值税专用发票'],
	[3, '9%增值税专用发票'],
	[4, '10%增值税专用发票'],
	[5, '11%增值税专用发票'],
	[6, '13%增值税专用发票']
]);

const getGivenArray = (data: any[], given?: string[]) => {
	if (!Array.isArray(data) || !given) {
		return data;
	}

	return data.map((item) => {
		return given.reduce((obj: { [key: string]: any }, keyName) => {
			obj[keyName] = item?.[keyName];
			return obj;
		}, {});
	});
};

interface infoType {
	beforeInfo?: any;
	afterInfo?: any;
	judge: string;
	extList?: any[];
}
const ExamineInfo: FC<infoType> = (props): ReactElement => {
	const { beforeInfo, afterInfo, judge, extList } = props;
	const [info, setInfo] = useState<any>({});
	const [previewVisible, setPreviewVisible] = useState<boolean>(false);

	useEffect(() => {
		console.log('beforeInfo --- ', JSON.parse(JSON.stringify(beforeInfo)));
		console.log('afterInfo --- ', JSON.parse(JSON.stringify(afterInfo)));
		if (judge === 'before') {
			setInfo(JSON.parse(JSON.stringify(beforeInfo)));
		} else if (judge === 'after') {
			setInfo(JSON.parse(JSON.stringify(afterInfo)));
		}
	}, []);

	const judgeSource = (materItem: any, before: any[] = [], str: string) => {
		const arr: any[] = before?.map((item: any) => {
			return item[str];
		});
		if (arr.includes(materItem)) {
			return true;
		}
		return false;
	};

	const getSkuThead = (saleType: 'isOnce' | 'isPurchase', unit: string, measureUnit?: number) => {
		const _unit = saleType === 'isOnce' || measureUnit === 1 ? unit : '箱';
		return [
			{
				name: `供应价（元/${_unit}）`,
				key: 'purchasePrice'
			},
			{
				name: `市场价（元/${_unit}）`,
				key: 'marketPrice'
			}
		];
	};

	const getSampleSkuThead = () => {
		return [
			{
				name: `样品价格（元）`,
				key: 'supplierSamplePrice'
			}
		];
	};

	const purchaseUnit = info?.purchaseParam?.measureUnit === 1 ? info?.unit : '箱';

	const isChangeStyle = (beforeData: any, afterData: any, given?: string[]) =>
		comparisonInfo(judge, getGivenArray(beforeData, given), getGivenArray(afterData, given))
			? {}
			: { color: '#f43636' };
	const showMoney: any = (data: any) => (+data / 100).toFixed(2);
	const getUnit = (type: any, unitType: any) => (type === 1 ? '件' : unitType ? '重' : '公斤');
	const FreightCard = ({ item }: any) => {
		return (
			<div className="temp_container">
				<div className="temp_head_container">
					<div className="head_info">
						<p className="temp_name">{item.templateName}</p>
					</div>
					<div className="fx-flexbox"></div>
				</div>
				<div className="temp_content_container">
					<Row className="temp_content_title">
						<Col span={8}>运送至</Col>
						<Col span={9}>
							费率(
							{item.isFreeShipping ? '卖家承担运费' : `按${getUnit(item.tradeType, true)}计费`})
						</Col>
						<Col span={7}>不可配送区域</Col>
					</Row>
					{item.rules.map((itemIn: any, indexIn: any) => (
						<Row className="temp_content_info" key={indexIn}>
							<Col span={8}>{itemIn.isDefault === 1 || itemIn.isDefault === null ? '全国' : itemIn.provinceNames}</Col>

							<Col span={9}>
								{item.isFreeShipping
									? '包邮'
									: `${item.tradeType === 1 ? itemIn.firstNumber : itemIn.firstNumber / 1e6}${getUnit(
											item.tradeType,
											null
									  )}以内${showMoney(itemIn.firstPrice)}元；每增加${
											item.tradeType === 1 ? itemIn.nextNumber : itemIn.nextNumber / 1e6
									  }${getUnit(item.tradeType, null)}，增加运费${showMoney(itemIn.nextPrice)}元`}
							</Col>
							<Col span={7}>{indexIn === 0 && (item.noDeliveryRegionNames || '无')}</Col>
						</Row>
					))}
				</div>
			</div>
		);
	};
	return (
		<>
			<div className="examine-details">
				<div className="examine-details-detail">
					<div className="detail-info">{judge === 'before' ? '变更前的商品信息' : '变更后的商品信息'}</div>

					<Title level={4}>销售类型</Title>
					<Descriptions className="pl-20" labelStyle={{ width: 110, textAlign: 'right', display: 'inline-block' }}>
						<Descriptions.Item
							label="销售类型"
							labelStyle={isChangeStyle(
								[beforeInfo?.isPurchase, beforeInfo?.isOnce],
								[afterInfo?.isPurchase, afterInfo?.isOnce]
							)}>
							<Space>
								{!!info?.isPurchase && <Text strong>集采批发</Text>}
								{!!info?.isOnce && <Text strong>一件代发</Text>}
							</Space>
						</Descriptions.Item>
					</Descriptions>

					<Title level={4} className="mt-20">
						基础信息
					</Title>
					<Descriptions
						className="pl-20"
						column={2}
						title="基础属性"
						labelStyle={{ width: 110, textAlign: 'right', display: 'inline-block' }}>
						<Descriptions.Item
							label="商品视频"
							span={2}
							labelStyle={isChangeStyle(beforeInfo?.videoUrl, afterInfo?.videoUrl)}>
							{!!info?.videoUrl && (
								<div className="fx__img-wrap">
									<div className="preview-img" onClick={() => setPreviewVisible(true)}>
										<img src={info?.videoImageUrl} alt="" />
										<PlayCircleOutlined />
									</div>
								</div>
							)}
						</Descriptions.Item>
						<Descriptions.Item
							label="商品主图"
							span={2}
							labelStyle={isChangeStyle(beforeInfo?.imageList, afterInfo?.imageList)}>
							<Space>
								{!!info.imageList?.length &&
									info?.imageList?.map((item: any) => {
										return (
											<div key={item} className="image-border">
												<Image key={item} src={item} width={86} />
											</div>
										);
									})}
							</Space>
						</Descriptions.Item>
						<Descriptions.Item span={2} label="商品名称" labelStyle={isChangeStyle(beforeInfo?.name, afterInfo?.name)}>
							<Text>{info?.name}</Text>
						</Descriptions.Item>
						<Descriptions.Item
							span={2}
							label="商品描述"
							labelStyle={isChangeStyle(beforeInfo?.description, afterInfo?.description)}>
							<Text>{info?.description}</Text>
						</Descriptions.Item>
						<Descriptions.Item
							span={2}
							label="商品卖点"
							labelStyle={isChangeStyle(beforeInfo?.sellingPoint, afterInfo?.sellingPoint)}>
							<Text>{info?.sellingPoint}</Text>
						</Descriptions.Item>
						{/* <Descriptions.Item
              span={2}
              label="商品参数"
              labelStyle={isChangeStyle(beforeInfo?.parameter, afterInfo?.parameter)}>
              <Text>{info?.parameter}</Text>
            </Descriptions.Item> */}
						{/* <Descriptions.Item
              label="商品类目"
              labelStyle={isChangeStyle(beforeInfo?.categoryTreeName, afterInfo?.categoryTreeName)}>
              <Text>{info?.categoryTreeName}</Text>
            </Descriptions.Item> */}
						<Descriptions.Item
							label="品牌"
							labelStyle={isChangeStyle(beforeInfo?.goodsBrandName, afterInfo?.goodsBrandName)}>
							<Text>{info?.goodsBrandName}</Text>
						</Descriptions.Item>
						<Descriptions.Item label="商品单位" labelStyle={isChangeStyle(beforeInfo?.unit, afterInfo?.unit)}>
							<Text>{info?.unit}</Text>
						</Descriptions.Item>
						<Descriptions.Item
							label="发票类型"
							labelStyle={isChangeStyle(beforeInfo?.invoiceType, afterInfo?.invoiceType)}>
							<Text>{TaxRateOption.get(info?.invoiceType)}</Text>
						</Descriptions.Item>
						{/* <Descriptions.Item
              label="参考价"
              labelStyle={isChangeStyle(beforeInfo?.referencePrice, afterInfo?.referencePrice)}>
              {info?.referencePrice && <Text>{`¥${(info?.referencePrice / 100).toFixed(2)}`}</Text>}
            </Descriptions.Item> */}
						{!!info.goodsNo && info.isExistSpec === 0 && (
							<Descriptions.Item label="商品货号" labelStyle={isChangeStyle(beforeInfo?.goodsNo, afterInfo?.goodsNo)}>
								<Text>{info?.goodsNo}</Text>
							</Descriptions.Item>
						)}
						<Descriptions.Item
							label="商品分类"
							labelStyle={isChangeStyle(beforeInfo?.flagCategoryInfo?.treeName, afterInfo?.flagCategoryInfo?.treeName)}>
							<Text>{info?.flagCategoryInfo?.treeName}</Text>
						</Descriptions.Item>
						<Descriptions.Item
							label="京东慧采价"
							labelStyle={isChangeStyle(beforeInfo?.jdWisdomPurchasePrice, afterInfo?.jdWisdomPurchasePrice)}>
							<Text>¥{(info?.jdWisdomPurchasePrice / 100).toFixed(2)}</Text>
						</Descriptions.Item>
						<Descriptions.Item
							label="京东慧采链接"
							labelStyle={isChangeStyle(beforeInfo?.jdWisdomPurchaseUrl, afterInfo?.jdWisdomPurchaseUrl)}>
							<Text>{info?.jdWisdomPurchaseUrl}</Text>
						</Descriptions.Item>
						<Descriptions.Item
							label="适用行业"
							labelStyle={isChangeStyle(beforeInfo?.flagIndustryList, afterInfo?.flagIndustryList)}>
							<div className="goods-tag">
								{info?.flagIndustryList?.map((item: any) => {
									return (
										<Tag
											color={`${judgeSource(item?.id, beforeInfo?.flagIndustryList, 'id') ? '' : 'red'}`}
											style={{ marginBottom: '5px' }}
											key={item?.id}>
											{item.flag}
										</Tag>
									);
								})}
							</div>
						</Descriptions.Item>
						<Descriptions.Item
							label="商品标签"
							labelStyle={isChangeStyle(beforeInfo?.flagTagsList, afterInfo?.flagTagsList)}>
							<div className="goods-tag">
								{info?.flagTagsList?.map((item: any) => {
									return (
										<Tag
											key={item?.id}
											style={{ marginBottom: '5px' }}
											color={`${judgeSource(item?.id, beforeInfo?.flagTagsList, 'id') ? '' : 'red'}`}>
											{item.flag}
										</Tag>
									);
								})}
							</div>
						</Descriptions.Item>
						<Descriptions.Item
							label="适用场景"
							labelStyle={isChangeStyle(beforeInfo?.flagSceneList, afterInfo?.flagSceneList)}>
							<div className="goods-tag">
								{info?.flagSceneList?.map((item: any) => {
									return (
										<Tag
											key={item.id}
											style={{ marginBottom: '5px' }}
											color={`${judgeSource(item?.id, beforeInfo?.flagSceneList, 'id') ? '' : 'red'}`}>
											{item.flag}
										</Tag>
									);
								})}
							</div>
						</Descriptions.Item>
						<Descriptions.Item label="包装" labelStyle={isChangeStyle(beforeInfo?.packaging, afterInfo?.packaging)}>
							<Text>{info?.packaging}</Text>
						</Descriptions.Item>
						<Descriptions.Item label="套装" labelStyle={isChangeStyle(beforeInfo?.suit, afterInfo?.suit)}>
							<Text>{info?.suit}</Text>
						</Descriptions.Item>
						<Descriptions.Item label="风格" labelStyle={isChangeStyle(beforeInfo?.style, afterInfo?.style)}>
							<Text>{info?.style}</Text>
						</Descriptions.Item>
						<Descriptions.Item
							label="适用人群"
							labelStyle={isChangeStyle(beforeInfo?.befitPerson, afterInfo?.befitPerson)}>
							<Text>{info?.befitPerson}</Text>
						</Descriptions.Item>
						<Descriptions.Item
							label="送礼对象"
							labelStyle={isChangeStyle(beforeInfo?.befitPresent, afterInfo?.befitPresent)}>
							<Text>{info?.befitPresent}</Text>
						</Descriptions.Item>
						{info?.propertyList?.map((item: any, index: number) => {
							return (
								<Descriptions.Item label={item?.propertyName} key={`${item?.propertyName}_${index}`}>
									<Text>{item?.propertyValue}</Text>
								</Descriptions.Item>
							);
						})}
						{extList?.length &&
							extList.map((item: any, index: number) => {
								let arr = [];
								if (item.fieldType == 'multiSelect') {
									arr = info?.extendFields?.[item?.field] || [];
								}
								return (
									<Descriptions.Item
										label={item?.title}
										key={`${item?.field}`}
										labelStyle={isChangeStyle(
											beforeInfo?.extendFields?.[item?.field],
											afterInfo?.extendFields?.[item?.field]
										)}>
										{item.fieldType == 'multiSelect' ? (
											<div className="goods-tag">
												{arr?.map((item: any) => {
													return (
														<Tag key={item.id} style={{ marginBottom: '5px' }}>
															{item}
														</Tag>
													);
												})}
											</div>
										) : (
											<Text>{info?.extendFields?.[item?.field]}</Text>
										)}
									</Descriptions.Item>
								);
							})}
					</Descriptions>

					{!!info?.skuList?.length && (
						<Descriptions
							className="pl-20"
							title="规格属性"
							labelStyle={{ width: 110, textAlign: 'right', display: 'inline-block' }}>
							<Descriptions.Item
								label="商品规格"
								labelStyle={isChangeStyle(beforeInfo?.skuList, afterInfo?.skuList, ['image', 'specList'])}>
								<SkuTable
									skuList={info?.skuList}
									otherThead={[
										{
											name: '规格图片',
											key: 'image',
											type: 'image',
											mergeRowSpan: true
										}
									]}
								/>
							</Descriptions.Item>
						</Descriptions>
					)}

					<Title level={4} className="mt-20">
						销售信息
					</Title>
					{info.isPurchase === 1 && (
						<Descriptions
							className="pl-20"
							title="集采交易信息"
							column={1}
							labelStyle={{ width: 110, textAlign: 'right', display: 'inline-block' }}>
							<Descriptions.Item
								label="计量单位"
								labelStyle={isChangeStyle(
									beforeInfo?.purchaseParam?.measureUnit,
									afterInfo?.purchaseParam?.measureUnit
								)}>
								<Text>{`按${purchaseUnit}销售`}</Text>
							</Descriptions.Item>
							<Descriptions.Item
								label="是否含税"
								labelStyle={isChangeStyle(
									beforeInfo?.purchaseParam?.isContainsTax,
									afterInfo?.purchaseParam?.isContainsTax
								)}>
								<Text>{info?.purchaseParam?.isContainsTax === 1 ? '含税' : '不含税'}</Text>
							</Descriptions.Item>

							{info?.isExistSpec === 0 && (
								<>
									<Descriptions.Item
										label="供货价"
										labelStyle={isChangeStyle(
											beforeInfo?.purchaseParam?.purchasePrice,
											afterInfo?.purchaseParam?.purchasePrice
										)}>
										<Text>¥{(info?.purchaseParam?.purchasePrice / 100).toFixed(2)}</Text>
									</Descriptions.Item>
									<Descriptions.Item
										label="市场价"
										labelStyle={isChangeStyle(
											beforeInfo?.purchaseParam?.marketPrice,
											afterInfo?.purchaseParam?.marketPrice
										)}>
										<Text>¥{(info?.purchaseParam?.marketPrice / 100).toFixed(2)}</Text>
									</Descriptions.Item>
								</>
							)}
							{info?.isExistSpec === 1 && (
								<>
									<Descriptions.Item
										label="规格单价"
										labelStyle={isChangeStyle(
											beforeInfo?.purchaseParam?.skuPriceList,
											afterInfo?.purchaseParam?.skuPriceList,
											['marketPrice', 'purchasePrice', 'specList', 'goodsNo']
										)}>
										<SkuTable
											skuPriceList={info?.purchaseParam?.skuPriceList || []}
											skuList={info?.skuList || []}
											thead={getSkuThead('isPurchase', info?.unit, info?.purchaseParam?.measureUnit)}
											otherThead={[
												{
													name: '规格货号',
													key: 'goodsNo'
												}
											]}
										/>
									</Descriptions.Item>
								</>
							)}
							{info?.purchaseParam?.isSupportSample === 1 && (
								<>
									<Descriptions.Item
										label="是否支持拿样"
										labelStyle={isChangeStyle(
											beforeInfo?.purchaseParam?.isSupportSample,
											afterInfo?.purchaseParam?.isSupportSample
										)}>
										<Text>{info?.purchaseParam?.isSupportSample === 1 ? '是' : '否'}</Text>
									</Descriptions.Item>
									{info?.purchaseParam?.sampleSalesInfo?.samplePricingType === 1 && (
										<Descriptions.Item
											label="样品价格"
											labelStyle={isChangeStyle(
												beforeInfo?.purchaseParam?.sampleSalesInfo?.supplierSamplePrice,
												afterInfo?.purchaseParam?.sampleSalesInfo?.supplierSamplePrice
											)}>
											<Text>¥{(info?.purchaseParam?.sampleSalesInfo?.supplierSamplePrice / 100).toFixed(2)}</Text>
										</Descriptions.Item>
									)}
									{info?.purchaseParam?.sampleSalesInfo?.samplePricingType === 2 && (
										<Descriptions.Item
											label="样品价格"
											labelStyle={isChangeStyle(
												beforeInfo?.purchaseParam?.sampleSalesInfo?.sampleSkuPriceList,
												afterInfo?.purchaseParam?.sampleSalesInfo?.sampleSkuPriceList,
												['supplierSamplePrice']
											)}>
											<SkuTable
												skuPriceList={info?.purchaseParam?.sampleSalesInfo?.sampleSkuPriceList || []}
												skuList={info?.purchaseParam?.sampleSalesInfo?.sampleSkuPriceList || []}
												thead={getSampleSkuThead()}
												otherThead={
													info?.purchaseParam?.sampleSalesInfo?.sampleLimitType === 3
														? [
																{
																	name: `限售数量（${purchaseUnit}）`,
																	key: 'sampleStock'
																}
														  ]
														: []
												}
											/>
										</Descriptions.Item>
									)}
									{info?.purchaseParam?.sampleSalesInfo?.sampleLimitType === 2 && (
										<Descriptions.Item
											label="样品限售总数"
											labelStyle={isChangeStyle(
												beforeInfo?.purchaseParam?.sampleSalesInfo?.sampleLimitTotal,
												afterInfo?.purchaseParam?.sampleSalesInfo?.sampleLimitTotal
											)}>
											<Text>
												{info?.purchaseParam?.sampleSalesInfo?.sampleLimitTotal}
												{purchaseUnit}
											</Text>
										</Descriptions.Item>
									)}
									{info?.purchaseParam?.sampleSalesInfo?.sampleLimitType === 1 && (
										<Descriptions.Item
											label="样品限售总数"
											labelStyle={isChangeStyle(
												beforeInfo?.purchaseParam?.sampleSalesInfo?.sampleLimitTotal,
												afterInfo?.purchaseParam?.sampleSalesInfo?.sampleLimitTotal
											)}>
											<Text>不限（按库存售卖）</Text>
										</Descriptions.Item>
									)}
									<Descriptions.Item
										label="限售规则"
										labelStyle={isChangeStyle(
											beforeInfo?.purchaseParam?.sampleSalesInfo?.sampleLimitRules,
											afterInfo?.purchaseParam?.sampleSalesInfo?.sampleLimitRules
										)}>
										<Text>
											{info?.purchaseParam?.sampleSalesInfo?.sampleLimitRules === 1
												? `按企业限售，每个企业限售${info?.purchaseParam?.sampleSalesInfo?.sampleLimitRulesNum}${purchaseUnit}`
												: `按人限售，每个人限售${info?.purchaseParam?.sampleSalesInfo?.sampleLimitRulesNum}${purchaseUnit}`}
										</Text>
									</Descriptions.Item>

									{info?.purchaseParam?.sampleSalesInfo?.sampleFreightTemplate && (
										<Descriptions.Item
											label="样品运费模板"
											labelStyle={isChangeStyle(
												beforeInfo?.purchaseParam?.sampleSalesInfo?.sampleFreightTemplate,
												afterInfo?.purchaseParam?.sampleSalesInfo?.sampleFreightTemplate
											)}>
											<div className="temp_list_container">
												{info?.purchaseParam?.sampleSalesInfo?.sampleFreightTemplate && (
													<FreightCard
														key={info?.purchaseParam?.sampleSalesInfo?.sampleFreightTemplate.freightTemplateId}
														item={info?.purchaseParam?.sampleSalesInfo?.sampleFreightTemplate}
													/>
												)}
											</div>
										</Descriptions.Item>
									)}
								</>
							)}
						</Descriptions>
					)}

					{info.isOnce === 1 && (
						<Descriptions
							className="pl-20 mt-20"
							title="一件代发交易信息"
							column={1}
							labelStyle={{ width: 110, textAlign: 'right', display: 'inline-block' }}>
							<Descriptions.Item
								label="是否含税"
								labelStyle={isChangeStyle(beforeInfo?.onceParam?.isContainsTax, afterInfo?.onceParam?.isContainsTax)}>
								<Text>{info?.onceParam?.isContainsTax === 1 ? '含税' : '不含税'}</Text>
							</Descriptions.Item>

							{info?.isExistSpec === 0 && (
								<>
									<Descriptions.Item
										label="供货价"
										labelStyle={isChangeStyle(
											beforeInfo?.onceParam?.purchasePrice,
											afterInfo?.onceParam?.purchasePrice
										)}>
										<Text>¥{(info?.onceParam?.purchasePrice / 100).toFixed(2)}</Text>
									</Descriptions.Item>
									<Descriptions.Item
										label="市场价"
										labelStyle={isChangeStyle(beforeInfo?.onceParam?.marketPrice, afterInfo?.onceParam?.marketPrice)}>
										<Text>¥ {(info?.onceParam?.marketPrice / 100).toFixed(2)}</Text>
									</Descriptions.Item>
								</>
							)}
							{info?.isExistSpec === 1 && (
								<>
									<Descriptions.Item
										label="规格单价"
										labelStyle={isChangeStyle(beforeInfo?.onceParam?.skuPriceList, afterInfo?.onceParam?.skuPriceList, [
											'marketPrice',
											'purchasePrice',
											'specList',
											'goodsNo'
										])}>
										<SkuTable
											skuPriceList={info?.onceParam?.skuPriceList || []}
											skuList={info?.skuList || []}
											thead={getSkuThead('isOnce', info?.unit)}
											otherThead={[
												{
													name: '规格货号',
													key: 'goodsNo'
												}
											]}
										/>
									</Descriptions.Item>
								</>
							)}
						</Descriptions>
					)}

					<Descriptions
						className="pl-20 mt-20"
						title="库存信息"
						column={1}
						labelStyle={{ width: 110, textAlign: 'right', display: 'inline-block' }}>
						<Descriptions.Item
							label="货品类型"
							labelStyle={isChangeStyle(
								[beforeInfo?.isSupportSpot, beforeInfo?.isSupportContract],
								[afterInfo?.isSupportSpot, afterInfo?.isSupportContract]
							)}>
							<Space>
								{info?.isSupportSpot === 1 && <Text strong>现货</Text>}
								{info?.isSupportContract === 1 && <Text strong>订货</Text>}
							</Space>
						</Descriptions.Item>
						<Descriptions.Item label="箱规" labelStyle={isChangeStyle(beforeInfo?.boxNumber, afterInfo?.boxNumber)}>
							{!!info?.boxNumber && <Text>{`一箱${info?.boxNumber}${info?.unit}`}</Text>}
						</Descriptions.Item>
						{info?.isSupportSpot === 1 && (
							<>
								<Descriptions.Item
									label="现货出货时间"
									labelStyle={isChangeStyle(beforeInfo?.shipmentDay, afterInfo?.shipmentDay)}>
									{!!info?.shipmentDay && <Text>{`${info?.shipmentDay}天内发货`}</Text>}
								</Descriptions.Item>
								<Descriptions.Item label="现货库存数量" labelStyle={isChangeStyle(beforeInfo?.stock, afterInfo?.stock)}>
									{info?.isSupportSpot === 1 &&
										(info?.isExistSpec ? (
											<SkuTable
												skuList={info?.skuList}
												otherThead={[
													{
														name: `库存数（${info?.unit || '个'}）`,
														key: 'stock'
													}
												]}
											/>
										) : (
											`${info?.stock || 0}${info?.unit}`
										))}
								</Descriptions.Item>
							</>
						)}
						{info?.isSupportContract === 1 && (
							<Descriptions.Item
								label="订货范围"
								labelStyle={isChangeStyle(beforeInfo?.reserveList, afterInfo?.reserveList)}>
								<div>
									<p style={{ marginBottom: 10 }}>
										{info.contractMin}≤订货数量≤{info.contractMax}
									</p>

									<ScopeTable
										unit={purchaseUnit}
										isReadOnly={true}
										value={info?.reserveList}
										previewText="预览"
										contractMin={info?.contractMin}
										contractMax={info?.contractMax}
										otherThead={[
											{
												name: `订货数量（${purchaseUnit}）`,
												isRequired: true,
												key: 'number',
												isEdit: true,
												max: 999999999,
												min: 1,
												precision: 0
											},
											{
												name: '预计出货时间（天）',
												isRequired: true,
												key: 'shipmentDay',
												isEdit: true,
												max: 999,
												min: 1,
												precision: 0
											}
										]}
									/>
								</div>
							</Descriptions.Item>
						)}
					</Descriptions>

					{info.isOnce === 1 && (
						<Descriptions
							className="pl-20 mt-20"
							title="一件代发快递运费"
							column={1}
							labelStyle={{ width: 110, textAlign: 'right', display: 'inline-block' }}>
							<Descriptions.Item
								label="发货地址"
								labelStyle={isChangeStyle(
									beforeInfo?.onceParam?.customerAddressName,
									afterInfo?.onceParam?.customerAddressName
								)}>
								<Text>{info?.onceParam?.customerAddressName}</Text>
							</Descriptions.Item>
							<Descriptions.Item
								label="退货地址"
								labelStyle={isChangeStyle(
									beforeInfo?.onceParam?.returnAddressName,
									afterInfo?.onceParam?.returnAddressName
								)}>
								<Text>{info?.onceParam?.returnAddressName}</Text>
							</Descriptions.Item>
							<Descriptions.Item
								label="运费模板"
								labelStyle={isChangeStyle(
									beforeInfo?.onceParam?.freightTemplateName,
									afterInfo?.onceParam?.freightTemplateName
								)}>
								{/* <Text>{info?.onceParam?.freightTemplateName}</Text> */}
								<div className="temp_list_container">
									{info?.onceParam?.freightTemplate && (
										<FreightCard key={info?.onceParam?.freightTemplate.key} item={info?.onceParam?.freightTemplate} />
									)}
								</div>
							</Descriptions.Item>
						</Descriptions>
					)}

					{info?.isPurchase === 1 && (
						<Descriptions
							className="pl-20 mt-20"
							title="集采批发物流运费"
							column={1}
							labelStyle={{ width: 110, textAlign: 'right', display: 'inline-block' }}>
							<Descriptions.Item
								label="发货地址"
								labelStyle={isChangeStyle(
									beforeInfo?.purchaseParam?.customerAddressName,
									afterInfo?.purchaseParam?.customerAddressName
								)}>
								<Text>{info?.purchaseParam?.customerAddressName}</Text>
							</Descriptions.Item>
							<Descriptions.Item
								label="退货地址"
								labelStyle={isChangeStyle(
									beforeInfo?.purchaseParam?.returnAddressName,
									afterInfo?.purchaseParam?.returnAddressName
								)}>
								<Text>{info?.purchaseParam?.returnAddressName}</Text>
							</Descriptions.Item>
							{info?.purchaseParam?.freightTemplateName && (
								<Descriptions.Item
									label="运费模板"
									labelStyle={isChangeStyle(
										beforeInfo?.purchaseParam?.freightTemplateName,
										afterInfo?.purchaseParam?.freightTemplateName
									)}>
									{/* <Text>{info?.purchaseParam?.freightTemplateName}</Text> */}
									<div className="temp_list_container">
										{info?.purchaseParam?.freightTemplate && (
											<FreightCard
												key={info?.purchaseParam?.freightTemplate.key}
												item={info?.purchaseParam?.freightTemplate}
											/>
										)}
									</div>
								</Descriptions.Item>
							)}
							{info?.purchaseParam?.freightDescription && (
								<Descriptions.Item
									label="运费说明"
									labelStyle={isChangeStyle(
										beforeInfo?.purchaseParam?.freightDescription,
										afterInfo?.purchaseParam?.freightDescription
									)}>
									<Text>{info?.purchaseParam?.freightDescription}</Text>
								</Descriptions.Item>
							)}
						</Descriptions>
					)}

					<Descriptions
						className="pl-20"
						title="重量体积"
						column={1}
						labelStyle={{ width: 110, textAlign: 'right', display: 'inline-block' }}>
						<Descriptions.Item label="单位重量" labelStyle={isChangeStyle(beforeInfo?.weight, afterInfo?.weight)}>
							{!!info?.weight && <Text>{`${(info?.weight / 1000000).toFixed(2)}千克/${info?.unit || '个'}`}</Text>}
						</Descriptions.Item>
						<Descriptions.Item
							label="包装体积"
							labelStyle={isChangeStyle(beforeInfo?.volumeText, afterInfo?.volumeText)}>
							<Text>{info?.volumeText}</Text>
						</Descriptions.Item>
					</Descriptions>

					<Descriptions
						className="pl-20"
						title="售后服务"
						labelStyle={{ width: 110, textAlign: 'right', display: 'inline-block' }}>
						<Descriptions.Item
							label="7天无理由退货"
							labelStyle={isChangeStyle(beforeInfo?.isAfterSales, afterInfo?.isAfterSales)}>
							{info?.isAfterSales === 0 ? '不支持' : '支持'}
						</Descriptions.Item>
					</Descriptions>

					{!!info?.goodsOrderInfoList?.length && (
						<Descriptions
							className="pl-20"
							title="下单信息"
							column={1}
							labelStyle={{ width: 110, textAlign: 'right', display: 'inline-block' }}>
							{info?.goodsOrderInfoList?.map((item: any, index: number) => {
								const beforeList = !!beforeInfo?.goodsOrderInfoList?.length ? beforeInfo?.goodsOrderInfoList : [];
								const afterList = !!afterInfo?.goodsOrderInfoList?.length ? afterInfo?.goodsOrderInfoList : [];

								const labelStyle = isChangeStyle(beforeList?.[index], afterList?.[index]);

								return (
									<Descriptions.Item key={index}>
										<Space size="large">
											<Text style={{ width: 150, display: 'inline-block', ...labelStyle }}>{`字段名：${
												item.keyName || ''
											}`}</Text>
											<Text style={{ width: 250, display: 'inline-block', ...labelStyle }}>{`提示内容：${
												item.keyValue || `请输入${item.keyName || ''}`
											}`}</Text>
											<Text style={{ ...labelStyle }}>{item.isMust ? '必填' : '非必填'}</Text>
										</Space>
									</Descriptions.Item>
								);
							})}
						</Descriptions>
					)}

					<Title level={4} className="mt-20">
						详情说明
					</Title>
					<Descriptions
						className="pl-20"
						column={1}
						labelStyle={{ width: 110, textAlign: 'right', display: 'inline-block' }}>
						<Descriptions.Item
							label="商品素材"
							labelStyle={isChangeStyle(beforeInfo?.materialList, afterInfo?.materialList)}>
							<Space direction="vertical" className="attribute-space">
								{info?.materialList?.map((item: any) => {
									return (
										<div className="goods-info" key={item.fileUrl}>
											{judge === 'after' && (
												<a
													className={`link-file ${
														judgeSource(item.fileUrl, beforeInfo?.materialList, 'fileUrl') ? '' : 'change-title'
													}`}
													href={item.fileUrl}>
													{item.fileName}
												</a>
											)}
											{judge === 'before' && (
												<a className="link-file" href={item.fileUrl}>
													{item.fileName}
												</a>
											)}
										</div>
									);
								})}
							</Space>
						</Descriptions.Item>
						<Descriptions.Item label="商品详情" labelStyle={isChangeStyle(beforeInfo?.detail, afterInfo?.detail)}>
							<div className="goods-detail" dangerouslySetInnerHTML={{ __html: formatRichText(info?.detail) }}></div>
						</Descriptions.Item>
					</Descriptions>
				</div>

				<Modal
					open={previewVisible}
					width={570}
					title="视频预览"
					footer={null}
					bodyStyle={{ padding: 10 }}
					onCancel={() => setPreviewVisible(false)}
					destroyOnClose>
					<video src={info?.videoUrl} width="550" poster={info?.videoImageUrl} controls></video>
				</Modal>
			</div>
		</>
	);
};

export default ExamineInfo;
